<template>
  <div v-if="hasPageAccess('internalHome')">
    <h2 aria-label="Internal Dashboard">
      Internal Dashboard
    </h2>
    <div id="dashboardContainer">
      <b-table-simple
        hover
      >
        <b-tbody>
          <b-tr>
            <b-th
              rowspan="2"
            >
              Agent Dashboard:
            </b-th>
            <b-td><i>Build Version</i></b-td>
            <b-td>
              <a
                :href="agentDashGitUrl"
                target="_blank"
              >
                {{ agentDashVersion }}
              </a>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td><i>Binaries</i></b-td>
            <b-td>
              <a
                :href="agentDashMac"
                target="_blank"
              >
                Mac
              </a>
              or
              <a
                :href="agentDashWindows"
                target="_blank"
              >
                Windows
              </a>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-btn
        v-if="hasPageAccess('internalSignup')"
        class="internal-button"
        variant="primary"
        size="lg"
        aria-label="Click to go to Internal Sign Up"
        @click="goToSignup()"
      >
        Internal Signup
      </b-btn>
      <b-btn
        v-if="hasPageAccess('internalGuestSignup') && !isASLAgent"
        class="internal-button"
        variant="primary"
        size="lg"
        aria-label="Click to go to Internal Guest Sign Up"
        @click="goToGuestSignup()"
      >
        Internal Guest Signup
      </b-btn>
      <b-btn
        v-if="hasPageAccess('internalResellerSignup') && !isASLAgent"
        class="internal-button"
        variant="primary"
        size="lg"
        aria-label="Click to go to Internal Reseller Sign Up"
        @click="goToResellerSignup()"
      >
        Internal Reseller Signup
      </b-btn>
      <b-btn
        v-if="hasPageAccess('report')"
        class="internal-button"
        variant="primary"
        size="lg"
        aria-label="Click to go to Report"
        @click="goToReport()"
      >
        Report
      </b-btn>
      <b-btn
          v-if="hasPageAccess('report') && !isASLAgent"
          class="internal-button"
          variant="primary"
          size="lg"
          aria-label="Click to go to Chat Report"
          @click="goToChatReport()"
      >
        Chat Report
      </b-btn>
      <b-btn
        v-if="hasPageAccess('agentActivity')"
        class="internal-button"
        variant="primary"
        size="lg"
        aria-label="Click to go to Agent Activity"
        @click="goToAgentActivity()"
      >
        Agent Activity
      </b-btn>
    </div>
    <LoginState />
  </div>
  <div v-else>
    <LoginState />
    <div id="dashboardContainer">
      <Error403 />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import yaml from 'yaml-js';
import { mapGetters } from 'vuex';
import * as role from '../../utils/permissions';

import Error403 from '../Error/403.vue';
import LoginState from '../Login/LoginState.vue';

export default {
  name: 'InternalHome',
  components: {
    Error403,
    LoginState,
  },
  data() {
    return {
      agentDashVersion: null,
    };
  },
  computed: {
    ...mapGetters([
      'needRefresh', 'isASLAgent',
    ]),
    agentDashGitUrl() {
      return `https://github.com/aira/dashboard/releases/tag/${this.agentDashVersion}`;
    },
    agentDashMac() {
      return `https://aira-bin.s3-accelerate.amazonaws.com/dashboard/latest/Aira+Agent+Dashboard-${this.agentDashVersion}.dmg`;
    },
    agentDashWindows() {
      return `https://aira-bin.s3-accelerate.amazonaws.com/dashboard/latest/Aira+Agent+Dashboard+Setup+${this.agentDashVersion}.exe`;
    },
  },
  beforeMount() {
    if (this.needRefresh) this.$router.go();
    axios.get(process.env.VUE_APP_DASHBOARD_AUTO_UPDATER_URL)
      .then((response) => {
        this.agentDashVersion = yaml.load(response.data).version;
      });
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    goToSignup() {
      this.$router.push('/internal/signup');
    },
    goToGuestSignup() {
      this.$router.push('/internal/guest-signup');
    },
    goToResellerSignup() {
      this.$router.push('/internal/reseller-signup');
    },
    goToReport() {
      this.$router.push('/internal/report');
    },
    goToChatReport() {
      this.$router.push('/internal/chatReport');
    },
    goToAgentActivity() {
      this.$router.push('/internal/agent-activity');
    },
  },
};
</script>

<style lang="scss">
#dashboardContainer {
  // border: solid 1px lightgrey;
  width: 500px;
  height: 750px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 3px;
  .internal-button {
    margin: 10px;
    width: 300px;
    height: 75px;
  }
}
</style>
